<template>
<div class="popup-container">
  <transition :key="i" v-for="(popup, i) in popups" name="bounceRight" appear>
    <m-elevation :level=2>
      <m-typography class="wrapper">
        <div>
          <m-typo-body :level=2>
            <b>
              {{ popup.fio_creator }}
            </b>
            <span>
              приглашает вас на дуэль!
            </span>
          </m-typo-body>
        </div>
        <div class="inner-wrapper">
          <m-button @click="acceptDuel(popup.duel_id)" raised dense>
            {{ !isAcceptLoading ? 'Принять' : null }}
            <clip-loader slot="icon"  :loading="isAcceptLoading" color="#ffffff" :size="'16px'"></clip-loader>
            </m-button>
          <m-button @click="cancelDuel(popup.duel_id)" dense>
            {{ !isCancelLoading ? 'Отказаться' : null }}
            <clip-loader slot="icon"  :loading="isCancelLoading" color="#005db8" :size="'16px'"></clip-loader>
          </m-button>
        </div>
      </m-typography>
    </m-elevation>
  </transition>
</div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import MButton from 'material-components-vue/dist/button'
import MTypography from 'material-components-vue/dist/typography'
import MElevation from 'material-components-vue/dist/elevation'
import MIcon from 'material-components-vue/dist/icon'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

Vue.use(MButton)
Vue.use(MTypography)
Vue.use(MElevation)
Vue.use(MIcon)

export default {
  name: 'hm-duel-popup',
  props: {
    debug: Boolean,
    url: {
      type: String,
      default() {
        return './duel/index/check-duel/'
      },
    },
    gameUrl: {
      type: String,
      default() {
        return './duel/index/start-duel'
      },
    },
    cancelUrl: {
      type: String,
      default() {
        return './duel/index/cancel-duel/'
      },
    },
    pollTime: {
      type: String,
      default() {
        return '15000'
      },
    },
  },
  data() {
    return {
      popups: [],
      isAcceptLoading: false,
      isCancelLoading: false,
    }
  },
  methods: {
    acceptDuel(duelId) {
      const that = this
      const url = that.gameUrl + `/duel_id/${duelId}`
      that.logout('Принимаем дуэль', duelId)
      that.logout('Переход по ссылке', url)
      that.isAcceptLoading = true
      window.location.href = url
    },
    cancelDuel(duelId) {
      const that = this
      const duel = duelId
      that.logout('Отменяем дуэль', duel)
      that.isCancelLoading = true
      axios
        .post(this.cancelUrl, {
          duel_id: duel,
        })
        .then(() => {
          that.popups = [...that.popups].filter(x => x.duel_id !== duel)
          that.logout('Попап удален', that.popups)
          that.isCancelLoading = false
        })
        .catch(reason => {
          that.logout('Ошибка в запросе')
          that.logout(reason)
          that.isCancelLoading = false
        })
    },
    logout() {
      if (this.debug) {
        // для дебага в условиях eLearningServer
        window.hm
          ? window.hm.core.Console.log(...arguments)
          : console.log(...arguments)
      }
    },
    checkGame() {
      const that = this
      that.logout('Проверяем наличие дуэлей с игроком по адресу', that.url)
      axios
        .get(that.url)
        .then(response => response.data)
        .then(data => {
          that.logout('Получены данные', data)
          that.popups = [...data]
          setTimeout(() => {
            that.checkGame()
          }, parseInt(that.pollTime, 10))
        })
        .catch(reason => {
          that.logout('Ошибка при запросе на проверку дуэлей')
          that.logout(reason)
          setTimeout(() => {
            that.checkGame()
          }, parseInt(that.pollTime, 10))
        })
    },
  },
  mounted() {
    const that = this
    setTimeout(() => {
      that.checkGame()
    }, 5000)

  },
  components: {
    ClipLoader,
  },
}
</script>

<style lang="scss" scoped>
$mdc-theme-primary: #005db8;
$mdc-theme-accent: #ff8105;
.wrapper {
  padding: 0.7rem;
  background-color: white;
}
.inner-wrapper {
  margin-top: 0.7rem;
  display: flex;
  justify-content: space-around;
}
b {
  color: $mdc-theme-primary;
}
.popup-container {
  position: absolute;
  right: 0;
  max-width: 320px;
  min-width: 200px;
  padding-right: 0.7rem;
  width: auto;
  z-index: 1;
  & > *:not(:first-of-type) {
    margin-top: 1rem;
  }
}
</style>

<style lang="scss">
$mdc-theme-primary: #005db8;
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500?ext=cyrilic');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

$animationDuration: 1.5s;
@import '~vue2-animate/src/sass/make-transitions';
@import '~vue2-animate/src/sass/animations/bouncing/all';

@import '~material-components-vue/dist/button/styles';
@import '~material-components-vue/dist/typography/styles';
@import '~material-components-vue/dist/elevation/styles';
</style>
